export default {
    data({ data }) {
        return data;
    },
    errors({ validationErrors }) {
        return validationErrors;
    },
    isLoading({ loading }) {
        return loading;
    },
    single({ single }) {
        return single;
    }
};
