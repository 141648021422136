import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const namespaced = true;

export default {
    namespaced,
    state: () => ({
        validationErrors: {},
        loading: false,
        single: {},
        data: []
    }),
    mutations,
    getters,
    actions
};
