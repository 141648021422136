<template>
  <b-container>
    <b-row class="mb-4">
      <b-col class="mb-3 mb-md-0" cols="12">
        <h1 class="font-weight-bold mb-0 text-primary">Dashboard</h1>
      </b-col>
    </b-row>

    <!-- Empty Sale Price Alert -->
    <b-row v-if="single.emptySalePrice > 0" class="mb-4 mt-4 mx-auto">
      <b-col cols="12" md="12">
        <b-card class="shadow-sm rounded bg-danger">
          <b-card-body class="d-flex flex-column align-items-center justify-content-center">
            <router-link
              :to="{ name: 'dash.orders.empty-price' }"
              class="text-white text-center">
              <h5 class="text-white text-center">Orders with empty sale price</h5>
              <span v-if="!isLoading" class="display-4">{{ single.emptySalePrice }}</span>
            </router-link>
            <b-spinner v-if="isLoading" class="mt-1" variant="secondary"></b-spinner>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-else class="mb-4 mt-4 mx-auto">
      <b-col cols="12" md="12">
        <b-card class="shadow-sm rounded bg-success mb-3">
          <b-card-body
            class=" text-white d-flex flex-column align-items-center justify-content-center">
            <h5 class="text-white text-center">Orders With Empty Price</h5>
            <span v-if="!isLoading" class="display-4">0</span>
            <b-spinner v-if="isLoading" class="mt-1" variant="secondary"></b-spinner>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- User Quotes and Sales Statistics -->
    <b-row class="mb-4 mt-4 mx-auto">
      <b-col v-if="single.companyQuotesToday > 0" cols="12" md="6">
        <b-card class="shadow-sm rounded mb-3">
          <b-card-body class="d-flex flex-column align-items-center justify-content-center">
            <h5 class="text-muted">Quotes Today</h5>
            <span v-if="!isLoading" class="display-4">{{ single.userQuotesToday }}</span>
            <b-spinner v-if="isLoading" class="mt-1" variant="secondary"></b-spinner>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col v-else cols="12" md="6">
        <b-card class="shadow-sm rounded mb-3">
          <b-card-body class="d-flex flex-column align-items-center justify-content-center">
            <h5 class="text-muted">Quotes Today</h5>
            <span v-if="!isLoading" class="display-4">0</span>
            <b-spinner v-if="isLoading" class="mt-1" variant="secondary"></b-spinner>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col v-if="single.companySalesToday > 0" cols="12" md="6">
        <b-card class="shadow-sm rounded mb-3">
          <b-card-body class="d-flex flex-column align-items-center justify-content-center">
            <h5 class="text-muted text-center">Orders Today</h5>
            <span v-if="!isLoading" class="display-4">{{ single.companySalesToday }}</span>
            <b-spinner v-if="isLoading" class="mt-1" variant="secondary"></b-spinner>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col v-else cols="12" md="6">
        <b-card class="shadow-sm rounded mb-3">
          <b-card-body class="d-flex flex-column align-items-center justify-content-center">
            <h5 class="text-muted">Sales Today</h5>
            <span v-if="!isLoading" class="display-4">0</span>
            <b-spinner v-if="isLoading" class="mt-1" variant="secondary"></b-spinner>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mb-4 mt-4 mx-auto">
      <!-- Ageing Quotes -->
      <b-col v-if="single.ageingQuotes > 0" cols="12" md="6">
        <b-card class="shadow-sm rounded bg-danger mb-3">
          <b-card-body class="d-flex flex-column align-items-center justify-content-center">
            <router-link :to="{ name: 'dash.quotes.ageing' }" class="text-white text-center">
              <h5 class="text-white text-center">Ageing Quotes</h5>
              <span v-if="!isLoading" class="display-4 text-white">{{ single.ageingQuotes }}</span>
            </router-link>
            <b-spinner v-if="isLoading" class="mt-1" variant="secondary"></b-spinner>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col v-else cols="12" md="6">
        <b-card class="shadow-sm rounded bg-success mb-3">
          <b-card-body
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <h5 class="text-white text-center">Ageing Quotes</h5>
            <span v-if="!isLoading" class="display-4 text-white">0</span>
            <b-spinner v-if="isLoading" class="mt-1" variant="secondary"></b-spinner>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Due Payments -->
      <b-col v-if="single.duePayments > 0" cols="12" md="6">
        <b-card class="shadow-sm rounded bg-danger mb-3">
          <b-card-body class="d-flex flex-column align-items-center justify-content-center">
            <router-link
              :to="{ name: 'dash.fittings.list', params: { paymentStatus: 'Unpaid', dateFrom: this.moment('2022-01-01'), dateTo:this.moment().add('4', 'days') }}"
              class="text-white text-center">
              <h5 class="text-white text-center">Fittings with Outstanding Payments </h5>
              <span v-if="!isLoading" class="display-4 text-white">{{ single.duePayments }}</span>
            </router-link>
            <b-spinner
              v-if="isLoading"
              class="mt-1"
              variant="secondary"
            ></b-spinner>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col v-else cols="12" md="6">
        <b-card class="shadow-sm rounded bg-success mb-3">
          <b-card-body class="d-flex flex-column align-items-center justify-content-center">
            <h5 class="text-white text-center">Fittings with Outstanding Payments</h5>
            <span v-if="!isLoading" class="display-4 text-white">0</span>
            <b-spinner v-if="isLoading" class="mt-1" variant="secondary"></b-spinner>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- Orders in the last 30 Days -->
    <b-row class="mb-4 mt-4 mx-auto">
      <b-col cols="12" md="12">
        <b-card class="shadow-sm rounded mb-3">
          <b-card-body class="d-flex flex-column align-items-center justify-content-center">
            <h5 class="text-muted text-center">Orders Within The Last 30 Days </h5>
            <span v-if="!isLoading" class="display-4">{{ single.salesThisMonth }}</span>
            <b-spinner v-if="isLoading" class="mt-1" variant="secondary"></b-spinner>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import currentUser from "../../mixins/currentUser";
import {mapActions, mapGetters} from "vuex";
import momentMixin from "../../mixins/momentMixin";

export default {
  mixins: [currentUser, momentMixin],
  created() {
    this.fetch();
  },
  computed: {
    ...mapGetters("dashboard", ["single", "isLoading"]),
  },
  data() {
    return {
      emptySalePrice: null,
      companyQuotesToday: null,
      companySalesToday: null,
      ageingQuotes: null,
      duePayments: null,
      salesThisMonth: null,
    };
  },
  methods: {
    ...mapActions("dashboard", ["fetch"]),
  },
};
</script>

<style>
</style>
