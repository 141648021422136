import {
    DASHBOARD_FETCH_REQUEST,
    DASHBOARD_FETCH_SUCCESS,
    DASHBOARD_FETCH_FAILURE
} from "./types";

export default {
    [DASHBOARD_FETCH_REQUEST](state) {
        state.single = {};
        state.loading = true;
    },
    [DASHBOARD_FETCH_SUCCESS](state, { data }) {
        state.single = data;
        state.loading = false;
    },
    [DASHBOARD_FETCH_FAILURE](state) {
        state.loading = false;
    }
};
