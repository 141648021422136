import {
    DASHBOARD_FETCH_REQUEST,
    DASHBOARD_FETCH_SUCCESS,
    DASHBOARD_FETCH_FAILURE
    // CLEAR_ERRORS
} from "./types";

export default {
    fetch({ commit }) {
        commit(DASHBOARD_FETCH_REQUEST);
        return window.axios
            .get(`/dashboard/statistics`)
            .then(response => {
                commit(DASHBOARD_FETCH_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(DASHBOARD_FETCH_FAILURE, error);
                throw error;
            });
    }
    // clearErrors({ commit }) {
    //     commit(CLEAR_ERRORS);
    // }
};
