<template>
	<router-view />
</template>

<script>
import dashboardModule from "../../store/dashboard"
export default {
	beforeCreate() {
		this.$store.registerModule("dashboard", dashboardModule)
	},
	destroyed() {
		this.$store.unregisterModule("dashboard")
	},
}
</script>
